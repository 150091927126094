/* @flow */

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { OffCanvasFilterMenu, ActiveFilters } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import { getCurrentPageInfo } from "state/current-page-info";
import { useSendMessage } from "crustate/react";
import Button from "components/Button";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import FilterIcon from "icons/filter.svg";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import { getSubPageCards } from "helpers/utils";
import useAffiliateListFilter from "components/AffiliateListView/use-affiliate-list-filter";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import SearchInput from "components/AffiliateListView/SearchInput";
import DropdownSort from "components/DropdownSort";
import ErrorView from "components/ErrorView";
import { Section, Title, Item } from "components/UiComponents";
import Carousel from "components/Carousel";
import AffiliateCard from "components/AffiliateCard";
import TopLevelCard from "components/AffiliateListView/TopLevelCard";

import styles from "./styles.scss";

type Props = {
  title: string,
  description: string,
};

const NUM_DUMMYCARDS = 32;

const AffiliateSubpage = ({ title, description }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { routes, content: { earnview } } = useContext(StoreInfoContext);
  const openFiltermenu = useOpenFiltermenu();
  const { width: browserWidth } = useBrowserDimensions();
  const { location: { pathname } } = useHistory();
  const isDesktop = browserWidth > 800;

  const {
    filterState,
    items,
    itemsSelected,
    searchValue,
    setSearchValue,
    affiliateCategoriesLoaded,
    affiliateListLoaded,
    affiliateListSelectedLoaded,
    affiliateCategoryError,
    affiliateListError } = useAffiliateListFilter();

  const subPagecards = getSubPageCards(earnview, pathname, routes.earnViewSubpage.url);

  useEffect(() => {
    sendMessage(getCurrentPageInfo("EARNVIEW"));
  }, []);

  return (
    <div className={styles.block}>
      <Helmet
        title={earnview.pageTitle}
      />
      <Wrapper variant="pageWrapper" className={cn(styles.wrapper, "awardit-affiliateListView awardit-pageWrapper")}>
        <div className={cn(styles.intro, "awardit-affiliateListViewIntro")}>
          {title &&
          <>
            <Title>
              {title}
            </Title>
            {/* eslint-disable react/no-danger */}
            {description &&
              <div dangerouslySetInnerHTML={{ __html: description }} />
            }
            {/* eslint-enable react/no-danger */}
          </>
          }
        </div>

        {earnview.affiliateListSelectedToggle &&
          <Section className={styles.affiliateListSelected}>
            <Title elem="h2">{earnview.affiliateListSelectedHeading}</Title>
            {affiliateListSelectedLoaded ?
              <Carousel
                autoplay
                className="awardit-affiliateListSelected"
                items={itemsSelected.map((p, i) => (
                  <Item key={`${p.name}_${i}`} className={cn("awardit-affiliateCard", styles.item)}>
                    <AffiliateCard affiliate={p} />
                  </Item>
                ))}
                slidesToScroll={isDesktop ? 4 : 2}
                slidesToShow={isDesktop ? 4 : 2}
                timer={5000}
              /> :
              <div className={styles.dummyCards}>
                <AffiliateDummyList
                  items={Array.from({
                    length: 4,
                  }, () => null)} />
              </div>
            }
          </Section>
        }

        <div className={styles.cardsWrapper}>
          {subPagecards &&
            subPagecards.map((card, i) => (
              <TopLevelCard
                key={i}
                heading={card.heading}
                image={card.image}
                linkUrl={card.link && card.link}
                description={card.description && card.description}
              />
            ))
          }
        </div>

        <Section className={styles.section}>
          <Title className={styles.heading}>
            {earnview.affiliateListHeading}
          </Title>
          <div className={styles.activeFiltersRow}>
            {filterState.active.filters.length > 0 &&
              <h2 className={styles.activeFiltersRowHeader}>
                {t("FILTER.ACTIVE_FILTERS.COUNT")}
                <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
              </h2>
            }
            <ActiveFilters
              hideEmptyFilters
              className={styles.activeFilters}
              filterState={filterState}
            />
          </div>
          {affiliateCategoriesLoaded &&
          <div>
            <div className={styles.filterWrapper}>
              <div className={styles.filterSection}>
                <Button
                  variant="medium"
                  slotLeft={<FilterIcon className={styles.filterIcon} />}
                  className={styles.filterButton}
                  onClick={openFiltermenu.openFiltermenu}
                >
                  {t("FILTER.FILTERBAR.ALL_FILTERS")}
                </Button>
                <div className={styles.inputs}>
                  <SearchInput
                    value={searchValue}
                    placeholderText={t("EARN.SEARCH")}
                    clearButtonAriaLabel={t("EARN.CLEAR_SEARCH")}
                    onChange={setSearchValue} />
                  <DropdownSort
                    className={styles.affiliateSort}
                    value={filterState.sort.value}
                    items={filterState.sort.values}
                    onChange={filterState.sort.setValue} />
                </div>
              </div>
            </div>

            {affiliateListLoaded && items.length > 0 &&
            <AffiliateList items={items} />
            }

            {affiliateListLoaded && items.length === 0 &&
            <div className={styles.emptyList}>
              <p>{earnview.emptyListMessage}</p>
            </div>
            }
          </div>
          }

          {!affiliateListLoaded && !affiliateListError &&
            <div className={styles.dummyCards}>
              <AffiliateDummyList
                items={Array.from({
                  length: NUM_DUMMYCARDS,
                }, () => null)} />
            </div>
          }

          {affiliateListError &&
            <ErrorView />
          }
        </Section>
      </Wrapper>
      {affiliateCategoriesLoaded && !affiliateCategoryError &&
        <OffCanvasFilterMenu
          isOpen={openFiltermenu.isOpen}
          close={openFiltermenu.closeFiltermenu}
          filterState={filterState} />
      }
    </div>
  );
};

export default AffiliateSubpage;
