/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { Menu } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu from "components/AppHeader/Large/SubMenu";
import HamburgerIcon from "icons/hamburger.svg";
import AdminSelectField from "components/AccountView/AdminSelectField";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  isScrolled: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({ className = "", mode, setMode, onHomeView, onCheckout, lastVisited, customer, logoWidth, isScrolled, onSuccess }: Props) => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const { routes } = storeInfo;
  const { content: { appheader: { compactMenu }, accountview } } = storeInfo;
  const { formatPoints } = useFormat();
  const showAdminField = accountview.showAdministration &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;

  const nav = navRoutes(ROUTES, storeInfo);

  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={styles.container}>
      <div className={cn(
        styles.block,
        className,
        { [styles.fullMenu]: onHomeView },
        { [styles.onHomeView]: onHomeView })}
      >
        <Link className={cn(styles.item, styles.logo)} to={routes.homeView.url}>
          <Logo
            width={logoWidth}
            isInverted={!isScrolled}
            onClick={() => setMode(MODE.NORMAL)}
          />
        </Link>

        <nav className={styles.nav}>
          {routes.accountView && routes.accountView.toggle && (
            <NavLink
              to={routes.accountView.url}
              activeClassName={styles.active}
              className={cn(styles.item, styles.item__customerBadge)}
            >
              <CustomerBadge customer={customer} />
            </NavLink>
          )}
          {showAdminField &&
          <div className={cn(styles.item, styles.item__adminFieldContainer)}>
            <AdminSelectField
              className={cn(styles.adminField)}
              text={accountview.administration}
              size="small"
            />
          </div>
          }
        </nav>
      </div>

      <nav className={cn(styles.nav, styles.linksNav)}>

        {!onCheckout && !compactMenu && nav.map(x => (
          <NavLink
            key={x.url}
            to={x.url}
            activeClassName={styles.active}
            className={styles.item}
          >
            {x.title}
          </NavLink>
        ))}

        <NavLink
          key="about"
          to="/about"
          activeClassName={styles.active}
          className={styles.item}
        >
          Om Team Rexel
        </NavLink>

        {compactMenu &&
          <Menu className={styles.item} icon={<HamburgerIcon />}>
            {!onCheckout && nav.map(x => (
              <Link key={x.url} to={x.url} className={styles.menuItem}>
                {x.title}
              </Link>
            ))}
          </Menu>
        }

        {expiringPointsDate && expiringPoints &&
          <NavLink
            to={routes.accountView.url}
            className={cn(styles.item, styles.expiresNotice)}
          >
            <div className={styles.exclamation}>!</div>
            <div className={styles.notice}>
              <span className={styles.date}>
                {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
              </span>
              <span className={styles.points}>
                {expiringPoints}
              </span>
            </div>
          </NavLink>
        }
      </nav>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess} />

      {!noDimModes.includes(mode) &&
        <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
      }

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;
