/* @flow */

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import LoginForm from "components/LoginForm";
import Button from "components/Button";
import { useErrorParams } from "helpers/use-error-params";
import { useTranslate } from "@awardit/react-use-translate";
import { setMode, MODE } from "state/view-mode";
import { useSendMessage } from "crustate/react";

import styles from "./styles.scss";

const LoginView = () => {
  const {
    content: { loginview, appfooter },
    configuration: { showCookieConsent },
  } = useContext(StoreInfoContext);
  const t = useTranslate();
  const sendMessage = useSendMessage();
  useErrorParams({ "bad_loginkey": "errorInvalidLogin" });
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  return (
    <div className={cn(styles.block, "awardit-loginView")}>
      <Helmet title={loginview.pageTitle} />
      <div className={styles.top}>
        <LoginForm className={styles.login_form} />
      </div>
      <div className={cn(styles.content, "awardit-loginViewContent")}>
        <div className={cn(styles.bottom, "awardit-loginViewBottom")}>
          <div className={styles.inner}>
            <h1>{loginview.heading}</h1>
            {/* eslint-disable react/no-danger */}
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: loginview.description }}
            />
            {/* eslint-enable react/no-danger */}
          </div>
          <div className={styles.footer}>
            <div className={styles.footerInner}>
              {loginview.customerServiceButtonUrl &&
                <span>{appfooter.copyrightText}</span>
              }

              {loginview.customerServiceButtonUrl &&
              <nav>
                {consentActivated &&
                  <Button
                    className={cn(
                      { [styles.spacing]: loginview.customerServiceButtonUrl },
                      styles.cookieButton
                    )}
                    onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                  >
                    {t("FOOTER.NEED_HELP.COOKIES")}
                  </Button>
                }
                <a href={loginview.customerServiceButtonUrl}>
                  {loginview.customerServiceButtonText}
                </a>
              </nav>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
