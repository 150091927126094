/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import useFormat from "helpers/use-format";
import { getCustomerActiveBalance } from "helpers/points";
import { StoreInfoContext } from "entrypoint/shared";
import UserIcon from "icons/user.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  customer: ?Customer,
};

const CustomerBadge = ({ customer, className }: Props) => {
  const { formatPoints } = useFormat();
  const { content: { appheader } } = useContext(StoreInfoContext);

  if (!customer) {
    return null;
  }

  const customerBalance = getCustomerActiveBalance(customer);
  const formattedBalance = formatPoints(customerBalance);

  return (
    <div className={cn(styles.block, className)}>
      {customer &&
        <div className={styles.content}>
          <span className={styles.points}>{formattedBalance}</span>
          <span className={styles.separator}>|</span>
          <span>{customer.firstname}</span>
          <span className={styles.separator}>|</span>
          <span>{customer.memberPoints && customer.memberPoints.slab && `${customer.memberPoints.slab}`}</span>
        </div>
      }
      {customer && customer.memberPoints && customer.memberPoints.slabImage ?
        <div className={appheader.slabImageBackground ? styles.slabBackground : styles.slabLarge}>
          <img src={customer.memberPoints.slabImage} />
        </div> : <UserIcon />}
    </div>
  );
};

export default CustomerBadge;
