/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useSendMessage, useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import { MemberGroupIsOwnerData } from "data";
import Button from "components/Button";
import Breadcrumbs from "components/Breadcrumbs";
import { logout } from "@crossroads/shop-state/customer";
import Wrapper from "components/Wrapper";
import RecruitAFriend from "components/RecruitAFriend";
import { BtnComponent } from "components/MemberGroupView";
import UserDetails from "components/AccountView/user-details";
import Password from "components/AccountView/password";
import YourPoints from "components/AccountView/your-points";
import LastOrders from "components/AccountView/last-orders";
import NewsletterSubscription from "components/AccountView/newsletter-subscription";
import AdditionalMemberNumbers from "components/AccountView/additional-member-numbers";
import ChevronIcon from "icons/chevron-mini.svg";

import styles from "./styles.scss";

const AccountView = ({ customer }: { customer: Customer }) => {
  const sendMessage = useSendMessage();
  const userMemberGroupData = useData(MemberGroupIsOwnerData);
  const {
    content, content: { accountview, tinkview },
    routes, routes: { accountView, accountTinkView, memberGroupView },
  } = useContext(StoreInfoContext);

  return (
    <Wrapper>
      <Helmet
        title={accountview.pageTitle}
      />
      <div className={styles.header}>
        <div>
          <Breadcrumbs current={accountview.title ?? ""} />
          <h1 className={styles.title}>{accountview.title}</h1>
        </div>
        <Button className={styles.logout} variant="link" onClick={() => sendMessage(logout())}>
          {accountview.logout}
        </Button>
      </div>

      {(userMemberGroupData.state === "LOADED" && userMemberGroupData.data.owner) && (
        memberGroupView && memberGroupView.toggle &&
        typeof memberGroupView.url === "string" && memberGroupView.url.length > 0) && (
        <BtnComponent
          basePath={`${accountView.url}${memberGroupView.url}`}
          page={accountView.url}
          routes={routes}
          content={content}
        />
      )}

      <div className={styles.container}>
        <main className={styles.left}>
          <div className={styles.row}>
            <div className={styles.box}>
              <UserDetails customer={customer} />
            </div>
          </div>
          {accountview.showRecruitAFriend &&
            <div className={styles.row}>
              <div className={styles.box}>
                <RecruitAFriend />
              </div>
            </div>
          }
        </main>
        <aside className={styles.right}>
          <div className={styles.row}>
            <div className={styles.box}>
              <YourPoints customer={customer} />
            </div>
          </div>
          {accountview.showAdditionalMemberNumbers &&
            <div className={styles.row}>
              <div className={styles.box}>
                <AdditionalMemberNumbers />
              </div>
            </div>
          }
          <div className={styles.row}>
            <div className={styles.box}>
              <LastOrders />
            </div>
          </div>
        </aside>
      </div>

      <div className={styles.container}>
        <div className={styles.left}>
          {accountview.showPassword && (
            <div className={styles.row}>
              <div className={styles.box}>
                <Password />
              </div>
            </div>
          )}
          {accountview.showMail &&
            <div className={styles.row}>
              <div className={styles.box}>
                <NewsletterSubscription customer={customer} />
              </div>
            </div>
          }
        </div>
        <div className={styles.right}>
          {(accountTinkView && accountTinkView.toggle) &&
            <div className={styles.row}>
              <div className={styles.box}>
                <header className={styles.boxHeader}>
                  <h2 className={styles.boxHeading}>{tinkview.heading}</h2>
                  <Button to={accountTinkView.url} type="button">
                    <span>{accountview.openTinkViewButtonCta}</span>
                    <ChevronIcon />
                  </Button>
                </header>
              </div>
            </div>
          }
        </div>
      </div>
    </Wrapper>
  );
};

export default AccountView;
