/* @flow */

import React, { useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData } from "data";
import { SecondaryLogo } from "components/AppHeader/Logo";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import cn from "classnames";
import { HeadlessLastOrders } from "components/AccountView/last-orders";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const sendMessage = useSendMessage();
  const {
    content: { appfooter, appfootertransactions },
    routes,
    configuration: { showCookieConsent },
  } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const {
    footerText,
    copyrightText,
    leftColumnHeading,
    leftColumnText,
    leftColumnButtonText,
    leftColumnButtonLink,
    rightColumnHeading,
    rightColumnText,
    rightColumnButtonText,
    rightColumnButtonLink,
    transactionsHeading,
    transactionsText,
    disableDividers,
  } = appfooter;
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const termsLink = routes.termsView?.toggle && routes.termsView.url && routes.termsView.title ?
    {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const displayTransactions = () => {
    let res = false;

    if (appfootertransactions.home && (location.pathname === "/" || location.pathname.includes("index.html"))) {
      res = true;
      return res;
    }

    if (appfootertransactions.other) {
      res = true;
    }

    for (const [key, value] of Object.entries(appfootertransactions)) {
      if (location.pathname.split("/")[1] === key) {
        res = value;
        return res;
      }
    }

    return res;
  };

  return (
    <footer className={cn(styles.block, className, "awardit-appFooter")}>
      <div className={styles.large}>

        {(leftColumnHeading || rightColumnHeading) &&
          <Wrapper className={styles.wrapper}>
            <div className={styles.section}>
              {leftColumnHeading &&
                <div className={styles.sectionDiv}>
                  <h2 className={styles.title}>{leftColumnHeading}</h2>

                  {/* eslint-disable react/no-danger */}
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: leftColumnText }} />
                  {/* eslint-enable react/no-danger */}
                  {leftColumnButtonLink && leftColumnButtonText &&
                    <Button className={styles.footerLink} to={leftColumnButtonLink}>
                      {leftColumnButtonText}
                    </Button>
                  }
                </div>
              }
              {rightColumnHeading &&
                <div className={styles.sectionDiv}>
                  <h2 className={styles.title}>{rightColumnHeading}</h2>
                  {/* eslint-disable react/no-danger */}
                  <div
                    className={styles.leftColumnText}
                    dangerouslySetInnerHTML={{ __html: rightColumnText }} />
                  {/* eslint-enable react/no-danger */}
                  {rightColumnButtonLink && rightColumnButtonText &&
                    <Button className={styles.footerLink} to={rightColumnButtonLink}>
                      {rightColumnButtonText}
                    </Button>
                  }
                </div>
              }
              <div className={cn(styles.sectionDiv, styles.logoWrapper)}>
                <SecondaryLogo width="290px" />
              </div>
            </div>
          </Wrapper>
        }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        {displayTransactions() && transaction.state === "LOADED" && transaction.data.length > 0 ? (
          <Wrapper>
            <div className={styles.transaction}>
              <div className={styles.left}>
                {transactionsHeading && (
                  <>
                    <h2 className={styles.title}>{transactionsHeading}</h2>
                    <p className={styles.description}>
                      {transactionsText}
                    </p>
                    <Button to={routes.accountView.url} variant="primary">
                      {t("FOOTER.LINKS.GO_TO_MY_ACCOUNT")}
                    </Button>
                  </>
                )}
              </div>
              <div className={styles.right}>
                <Link className={styles.link} to={routes.accountTransactionsView.url}>
                  <HeadlessLastOrders />
                </Link>
                <div className={styles.section__bottom}>
                  <Button
                    className={cn(styles.link, styles.centeredCta)}
                    to={routes.accountTransactionsView.url}
                  >
                    {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                  </Button>
                </div>
              </div>
            </div>
          </Wrapper>
        ) : null }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        <Wrapper className={styles.wrapper}>
          <div className={cn(styles.row, styles.additionalHtml)}>
            <section className={styles.content}>
              <p>
                {footerText}
              </p>
              {termsLink &&
                <Button
                  className={styles.link}
                  to={termsLink.url}
                >
                  {termsLink.title}
                </Button>
              }
              {consentActivated &&
                <Button
                  className={styles.link}
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
              }
            </section>
          </div>
        </Wrapper>

        {appfooter.additionalHtml &&
        <>
          {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
            <div className={cn("awardit-appFooterDivider", styles.divider)} />
          }

          <Wrapper className={styles.wrapper}>
            <section className={styles.copyright}>
              {/* eslint-disable react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: appfooter.additionalHtml }} />
              {/* eslint-enable react/no-danger */}
            </section>
          </Wrapper>
        </>
        }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        <Wrapper className={styles.wrapper}>
          <section className={styles.copyright}>
            <div className={styles.row}>
              <span>{copyrightText}</span>
            </div>
          </section>
        </Wrapper>
      </div>
    </footer>
  );
};

export default AppFooter;
